<template>
    <div>
        <app-layout>

            <template v-slot:header>
                <Header :title="$t('sending_sms')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('sending_sms')"
                              :is-filter="false"
                >
                </HeaderMobile>
            </template>
            <b-row>
                <b-col md="3">
                    <b-card bg-variant="light" class="text-center cursor-pointer" @click="$router.push('/send/sms/search/student')">
                        <b-card-text>
                            {{$t('Öğrenci Arama İle SMS Gönderimi').toUpper()}}
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col md="3">
                    <b-card bg-variant="light" class="text-center cursor-pointer" @click="showModal()">
                        <b-card-text>
                            {{$t('Cep Telefonu Bilgisi ile SMS Gönderimi').toUpper()}}
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col md="3">
                    <b-card bg-variant="light" class="text-center cursor-pointer" @click="$router.push('/send/sms/to-staff')">
                        <b-card-text>
                            {{$t('Personellere SMS Gönderimi').toUpper()}}
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>

            <CommonModal ref="showModal" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('sending_sms_with_mobile_phone_information').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                  <SendingSmsWithMobilePhoneInformation v-if="formProcess==='sendingSmsWithMobilePhoneInformation'" @closeModal="hideModal"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
// Services

// Page

// Other
import SendingSmsWithMobilePhoneInformation from "@/modules/sendSms/pages/SendingSmsWithMobilePhoneInformation.vue";

export default {
    components: {
        SendingSmsWithMobilePhoneInformation,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t("sending_sms"),
        };
    },
    data() {
        return {
            text: null,
            formId: 0,
            form: {},
            formatLines:[],
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: true,
                    },
                    {
                        label: this.$t("name"),
                        field: "name",
                        sortable: true,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        showModal() {
            this.formProcess='sendingSmsWithMobilePhoneInformation'
            this.$refs.showModal.$refs.commonModal.show()
        },
        hideModal() {
            this.formProcess=null
            this.$refs.showModal.$refs.commonModal.hide()
        },


    },
};
</script>
<style>
.addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 15px;
}
</style>
