<template>
    <div>
        <b-input-group class="mt-2">
            <template #prepend>
                <div class="numberOfRows">
                    <div v-for="(data,i) in formatLines" class="">
                        <span class="number">{{ i + 1 }}</span>

                    </div>
                </div>
            </template>
            <b-form-textarea
                id="textarea-formatter"
                v-model="text"
                placeholder="Telefon Numarası"
                rows="10"
                :formatter="formatter"
                @input="handleInput"
            ></b-form-textarea>
        </b-input-group>
    </div>
</template>
<script>

export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        validateError: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '100px'
        },

    },
    watch: {
        text(value){
            this.setSelect(value)
        }
    },
    data() {
        return {
            text: this.value,
            formatLines:[]
        }
    },
    methods: {
        formatter(value) {

            const lines = value.split('\n');

            const formattedLines = lines.map(line => {
                const numbers = line.replace(/\D/g, '');
                if (numbers.length === 0) {
                    return '';
                }

                const areaCode = numbers.substring(0, 3);

                const middlePart = numbers.substring(3, 6);

                const lastPart = numbers.substring(6, 10);

                return `(${areaCode}) ${middlePart} ${lastPart}`;
            });
            this.formatLines = formattedLines

            return formattedLines.join('\n');
        },
        setSelect(value) {
            if (value) {
                this.text = value.split(',').map(item => item.trim()).join('\n')
            } else this.text = '';
        },
        handleInput(event) {

            if (event) {
                let cleanedText=event.replaceAll(',', '').split('\n').map(item => item.trim()).join(',')
                cleanedText=cleanedText.replaceAll(/[()\s]/g,'')
                this.$emit('input', cleanedText)

            } else {
                this.$emit('input', null)
            }
        }
    }
};
</script>
<style scoped>

span.number {
    font-size: 10px;
}
.numberIcon {
    font-size: 12px;
    color: black;
    margin-right: 3px;
}

.numberOfRows {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 10px;
}
</style>
