<template>
    <div>
        <ValidationObserver ref="form">

            <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                    <ValidationProvider name="originator" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('originator')">
                            <parameter-selectbox v-model="form.originator" code="originators_code"
                                                 :validate-error="errors[0]"
                            >
                            </parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="description" rules="required" v-slot="{valid, errors}">

                        <b-form-group :label="$t('explanation')">
                            <b-form-input v-model="form.description"

                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="message" :rules="form.message_en ?'':'required'" v-slot="{valid, errors}">

                        <b-input-group prepend="TR" class="mb-2">
                            <b-form-textarea
                                id="textarea-rows"
                                v-model="form.message"
                                rows="10"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                        </b-form-invalid-feedback>
                    </ValidationProvider>

                    <ValidationProvider name="message_en" :rules="form.message ?'':'required'" v-slot="{valid, errors}">

                        <b-input-group prepend="EN">
                            <b-form-textarea
                                id="textarea-rows"
                                v-model="form.message_en"
                                rows="10"

                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </ValidationProvider>

                    <ValidationProvider name="phone_numbers" rules="required" v-slot="{valid, errors}">
                        <MultiTextPhoneNumber
                            v-model="form.phone_numbers"
                        />
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                        </b-form-invalid-feedback>
                    </ValidationProvider>
                    <b-button variant="primary" class="mt-4" @click="sendSms" :disabled="showButton">
                        {{ $t('send_sms') }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
import MultiTextPhoneNumber from "@/components/interactive-fields/MultiTextPhoneNumber.vue";
import sendSmsServices from "@/services/sendSmsServices";

export default {
    components: {MultiTextPhoneNumber},
    metaInfo() {
        return {
            title: this.$t('sending_sms_student_search')
        }
    },
    data() {
        return {
            showButton: true,
            form: {
                phone_numbers: null
            },
        }
    },
    watch: {
        'form.phone_numbers'(value) {
            if (value != null) {
                const arr = value.split(",")
                arr[0].length>=10 ? this.showButton = false : this.showButton = true
            } else {
                this.showButton = true
            }


        }
    },
    methods: {
        async sendSms() {
            let isValid = await this.$refs.form.validate()
            if (!isValid) {
                return;
            }
            const formData = {
                ...this.form,
            }

            if (this.form.phone_numbers != null) {
                const arrNumber = this.form.phone_numbers.split(',')
                formData.phone_numbers=arrNumber
            }

            sendSmsServices.sendSmsPhoneNumber(formData)
                .then(response => {
                    this.$toast.success(this.$t("api." + response.data.message))
                    this.$emit('closeModal', true)
                }).catch(err => {
                this.showErrors(err)
            })
        }


    }
}
</script>



